<template>
    <svg width="160" height="54" viewBox="0 0 1830 609" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M75.6948 0H194.65V189.229L75.6948 391.445V0Z" fill="white"></path>
        <path d="M282.805 101.515H430.775L148.786 580.971H0L282.805 101.515Z" fill="#FCCE01"></path>
        <path d="M465.945 101.515H614.138L411.996 446.061H264.397L465.945 101.515Z" fill="#FCCE01"></path>
        <path d="M614.152 101.515L739.192 446.061H587.214L518.795 264.03L614.152 101.515Z" fill="#B4B8BB"></path>
        <path d="M542.386 372.079H510.18V403.692H542.386V372.079Z" fill="white"></path>
        <path d="M501.191 372.079H468.985V403.692H501.191V372.079Z" fill="#FCCE01"></path>
        <path d="M501.191 412.818H468.985V444.43H501.191V412.818Z" fill="#FCCE01"></path>
        <path d="M542.386 412.818H510.18V444.43H542.386V412.818Z" fill="#FCCE01"></path>
        <path d="M720.625 154.573H971.521V225.441H886.702V456.226H805.37V225.367H720.625V154.573Z" fill="#B4B8BB"></path>
        <path d="M1007.37 154.573H1236.67V456.226H1155.41V225.367H1081.06L1076.75 338.236C1076.75 338.236 1080.32 443.24 1030 453.11C988.522 461.272 959.135 450.29 956.983 449.548V387.065H986.592C986.592 387.065 994.236 386.471 995.126 352.855C996.091 319.239 1007.37 154.573 1007.37 154.573Z" fill="#B4B8BB"></path>
        <path d="M1538.77 299.058C1539.37 254.162 1532.02 208.97 1532.02 208.97C1525.93 181.142 1506.64 169.566 1506.64 169.566C1482.82 150.643 1399.41 154.576 1399.41 154.576C1316 154.576 1300.42 169.566 1300.42 169.566C1261.01 187.301 1267.1 299.132 1267.1 299.132C1266.21 406.659 1277.19 413.708 1277.19 413.708C1295.22 454.374 1348.65 456.304 1348.65 456.304H1457.74C1513.62 450.293 1526.82 418.606 1526.82 418.606C1542.71 399.312 1538.77 299.058 1538.77 299.058ZM1454.55 368.739C1454.55 368.739 1453.36 384.471 1444.31 387.216C1444.31 387.216 1402.6 396.196 1364.09 387.216C1364.09 387.216 1351.62 387.587 1351.4 368.813C1351.18 350.038 1351.4 241.473 1351.4 241.473C1351.4 241.473 1351.99 225.741 1362.23 224.85C1362.23 224.85 1410.69 218.617 1443.93 224.85C1443.93 224.85 1453.88 227.596 1454.47 241.473V368.739H1454.55Z" fill="#B4B8BB"></path>
        <path d="M1569.65 154.573H1650.68V271.227H1743.37V154.573H1826.78V456.226H1742.11V336.455H1650.31V456.226H1569.65V154.573Z" fill="#B4B8BB"></path>
        <path d="M264 580.898V476.627H332.217V491.713H282.941V521.146H329.109V536.232H282.941V580.898H264Z" fill="white"></path>
        <path d="M375.129 582.377C369.9 582.377 365.214 581.391 361.071 579.419C357.026 577.349 353.82 574.588 351.453 571.137C349.184 567.686 348.049 563.791 348.049 559.453C348.049 554.128 349.43 549.937 352.192 546.881C354.955 543.726 359.443 541.458 365.658 540.077C371.873 538.697 380.209 538.007 390.666 538.007H395.845V534.901C395.845 529.971 394.76 526.421 392.59 524.252C390.42 522.082 386.77 520.998 381.64 520.998C377.595 520.998 373.452 521.639 369.21 522.921C364.968 524.104 360.676 525.977 356.336 528.541L351.009 515.969C353.574 514.194 356.582 512.666 360.035 511.384C363.587 510.004 367.286 508.968 371.133 508.278C375.079 507.489 378.779 507.095 382.232 507.095C392.787 507.095 400.63 509.56 405.76 514.49C410.89 519.322 413.454 526.865 413.454 537.119V580.898H396.141V569.362C394.464 573.405 391.801 576.609 388.151 578.976C384.501 581.244 380.16 582.377 375.129 582.377ZM378.976 569.658C383.81 569.658 387.805 567.982 390.962 564.629C394.218 561.277 395.845 557.037 395.845 551.909V548.656H390.814C381.541 548.656 375.079 549.395 371.429 550.874C367.878 552.255 366.102 554.818 366.102 558.565C366.102 561.819 367.237 564.481 369.506 566.552C371.775 568.623 374.931 569.658 378.976 569.658Z" fill="white"></path>
        <path d="M474.55 582.377C466.954 582.377 460.344 580.849 454.721 577.792C449.098 574.637 444.758 570.2 441.699 564.481C438.641 558.762 437.112 552.057 437.112 544.366C437.112 536.675 438.641 530.069 441.699 524.547C444.758 518.927 449.098 514.638 454.721 511.68C460.344 508.623 466.954 507.095 474.55 507.095C479.187 507.095 483.774 507.834 488.312 509.313C492.85 510.792 496.598 512.863 499.558 515.525L494.083 528.393C491.518 526.125 488.608 524.4 485.352 523.216C482.195 522.033 479.137 521.441 476.178 521.441C469.765 521.441 464.784 523.463 461.232 527.506C457.779 531.45 456.053 537.119 456.053 544.514C456.053 551.811 457.779 557.579 461.232 561.819C464.784 565.96 469.765 568.031 476.178 568.031C479.039 568.031 482.097 567.439 485.352 566.256C488.608 565.073 491.518 563.298 494.083 560.932L499.558 573.947C496.598 576.511 492.8 578.581 488.164 580.159C483.626 581.638 479.088 582.377 474.55 582.377Z" fill="white"></path>
        <path d="M518.599 493.044V475H539.316V493.044H518.599ZM519.783 580.898V508.574H538.28V580.898H519.783Z" fill="white"></path>
        <path d="M566.375 580.898L566.378 554.72V476.627H584.875V553.832L584.877 580.898H566.375Z" fill="white"></path>
        <path d="M612.267 493.044V475H632.984V493.044H612.267ZM613.451 580.898V508.574H631.948V580.898H613.451Z" fill="white"></path>
        <path d="M693.193 582.377C674.055 582.377 664.485 572.912 664.485 553.98V522.477H650.576V508.574H664.485V486.98H682.982V508.574H704.883V522.477H682.982V552.945C682.982 557.678 684.018 561.227 686.09 563.594C688.162 565.96 691.516 567.143 696.152 567.143C697.533 567.143 698.964 566.996 700.444 566.7C701.923 566.305 703.452 565.911 705.031 565.517L707.842 579.124C706.067 580.11 703.798 580.898 701.035 581.49C698.372 582.082 695.758 582.377 693.193 582.377Z" fill="white"></path>
        <path d="M733.383 607.521L746.701 577.94L716.662 508.574H736.343L756.615 559.157L777.332 508.574H795.977L752.472 607.521H733.383Z" fill="white"></path>
        <path d="M861.455 580.898V476.627H877.288L914.282 544.81L951.128 476.627H966.666V580.898H949.501V510.645L919.609 565.073H908.511L878.62 510.94V580.898H861.455Z" fill="white"></path>
        <path d="M1020.55 582.377C1015.32 582.377 1010.64 581.391 1006.5 579.419C1002.45 577.349 999.245 574.588 996.877 571.137C994.608 567.686 993.474 563.791 993.474 559.453C993.474 554.128 994.855 549.937 997.617 546.881C1000.38 543.726 1004.87 541.458 1011.08 540.077C1017.3 538.697 1025.63 538.007 1036.09 538.007H1041.27V534.901C1041.27 529.971 1040.18 526.421 1038.01 524.252C1035.84 522.082 1032.19 520.998 1027.06 520.998C1023.02 520.998 1018.88 521.639 1014.63 522.921C1010.39 524.104 1006.1 525.977 1001.76 528.541L996.433 515.969C998.998 514.194 1002.01 512.666 1005.46 511.384C1009.01 510.004 1012.71 508.968 1016.56 508.278C1020.5 507.489 1024.2 507.095 1027.66 507.095C1038.21 507.095 1046.05 509.56 1051.18 514.49C1056.31 519.322 1058.88 526.865 1058.88 537.119V580.898H1041.57V569.362C1039.89 573.405 1037.23 576.609 1033.58 578.976C1029.93 581.244 1025.58 582.377 1020.55 582.377ZM1024.4 569.658C1029.23 569.658 1033.23 567.982 1036.39 564.629C1039.64 561.277 1041.27 557.037 1041.27 551.909V548.656H1036.24C1026.97 548.656 1020.5 549.395 1016.85 550.874C1013.3 552.255 1011.53 554.818 1011.53 558.565C1011.53 561.819 1012.66 564.481 1014.93 566.552C1017.2 568.623 1020.36 569.658 1024.4 569.658Z" fill="white"></path>
        <path d="M1086.53 580.898V508.574H1104.59V519.815C1107.05 515.673 1110.36 512.518 1114.5 510.349C1118.74 508.18 1123.48 507.095 1128.71 507.095C1145.77 507.095 1154.31 517.004 1154.31 536.823V580.898H1135.81V537.711C1135.81 532.091 1134.72 527.999 1132.55 525.435C1130.48 522.871 1127.23 521.589 1122.79 521.589C1117.36 521.589 1113.02 523.315 1109.76 526.766C1106.61 530.118 1105.03 534.605 1105.03 540.225V580.898H1086.53Z" fill="white"></path>
        <path d="M1205.79 582.377C1200.57 582.377 1195.88 581.391 1191.74 579.419C1187.69 577.349 1184.49 574.588 1182.12 571.137C1179.85 567.686 1178.72 563.791 1178.72 559.453C1178.72 554.128 1180.1 549.937 1182.86 546.881C1185.62 543.726 1190.11 541.458 1196.32 540.077C1202.54 538.697 1210.88 538.007 1221.33 538.007H1226.51V534.901C1226.51 529.971 1225.43 526.421 1223.26 524.252C1221.09 522.082 1217.44 520.998 1212.31 520.998C1208.26 520.998 1204.12 521.639 1199.88 522.921C1195.63 524.104 1191.34 525.977 1187 528.541L1181.67 515.969C1184.24 514.194 1187.25 512.666 1190.7 511.384C1194.25 510.004 1197.95 508.968 1201.8 508.278C1205.75 507.489 1209.44 507.095 1212.9 507.095C1223.45 507.095 1231.3 509.56 1236.43 514.49C1241.56 519.322 1244.12 526.865 1244.12 537.119V580.898H1226.81V569.362C1225.13 573.405 1222.47 576.609 1218.82 578.976C1215.17 581.244 1210.83 582.377 1205.79 582.377ZM1209.64 569.658C1214.48 569.658 1218.47 567.982 1221.63 564.629C1224.88 561.277 1226.51 557.037 1226.51 551.909V548.656H1221.48C1212.21 548.656 1205.75 549.395 1202.1 550.874C1198.54 552.255 1196.77 554.818 1196.77 558.565C1196.77 561.819 1197.9 564.481 1200.17 566.552C1202.44 568.623 1205.6 569.658 1209.64 569.658Z" fill="white"></path>
        <path d="M1305.51 609C1298.8 609 1292.49 608.211 1286.57 606.634C1280.75 605.155 1275.62 602.837 1271.18 599.682L1276.51 586.815C1280.65 589.575 1285.09 591.597 1289.83 592.879C1294.56 594.259 1299.3 594.949 1304.03 594.949C1317.25 594.949 1323.86 588.589 1323.86 575.87V565.96C1321.79 570.003 1318.58 573.207 1314.24 575.574C1310 577.94 1305.27 579.124 1300.04 579.124C1293.53 579.124 1287.85 577.645 1283.02 574.687C1278.19 571.63 1274.44 567.39 1271.77 561.967C1269.11 556.544 1267.78 550.233 1267.78 543.035C1267.78 535.837 1269.11 529.576 1271.77 524.252C1274.44 518.829 1278.19 514.638 1283.02 511.68C1287.85 508.623 1293.53 507.095 1300.04 507.095C1305.46 507.095 1310.3 508.327 1314.54 510.792C1318.78 513.159 1321.89 516.364 1323.86 520.406V508.574H1341.91V573.651C1341.91 585.385 1338.81 594.21 1332.59 600.126C1326.38 606.042 1317.35 609 1305.51 609ZM1305.07 565.073C1310.79 565.073 1315.33 563.101 1318.68 559.157C1322.04 555.213 1323.71 549.839 1323.71 543.035C1323.71 536.232 1322.04 530.907 1318.68 527.062C1315.33 523.118 1310.79 521.146 1305.07 521.146C1299.35 521.146 1294.81 523.118 1291.45 527.062C1288.1 530.907 1286.42 536.232 1286.42 543.035C1286.42 549.839 1288.1 555.213 1291.45 559.157C1294.81 563.101 1299.35 565.073 1305.07 565.073Z" fill="white"></path>
        <path d="M1405.62 582.377C1397.43 582.377 1390.38 580.849 1384.46 577.792C1378.54 574.736 1373.95 570.397 1370.7 564.777C1367.54 559.157 1365.96 552.501 1365.96 544.81C1365.96 537.316 1367.49 530.759 1370.55 525.139C1373.71 519.519 1378 515.131 1383.42 511.976C1388.95 508.722 1395.21 507.095 1402.22 507.095C1412.48 507.095 1420.57 510.349 1426.48 516.857C1432.5 523.364 1435.51 532.238 1435.51 543.479V548.951H1383.72C1385.1 561.868 1392.5 568.327 1405.92 568.327C1409.96 568.327 1414.01 567.735 1418.05 566.552C1422.09 565.27 1425.79 563.298 1429.15 560.636L1434.33 573.06C1430.87 575.919 1426.53 578.187 1421.31 579.863C1416.08 581.539 1410.85 582.377 1405.62 582.377ZM1402.96 519.667C1397.53 519.667 1393.14 521.343 1389.79 524.695C1386.43 528.048 1384.41 532.584 1383.72 538.302H1420.12C1419.73 532.288 1418.05 527.703 1415.09 524.547C1412.23 521.294 1408.18 519.667 1402.96 519.667Z" fill="white"></path>
        <path d="M1459.33 580.898V508.574H1477.39V519.667C1479.56 515.723 1482.57 512.666 1486.41 510.497C1490.26 508.229 1494.7 507.095 1499.73 507.095C1510.58 507.095 1517.69 511.828 1521.04 521.294C1523.31 516.857 1526.56 513.405 1530.81 510.94C1535.05 508.377 1539.88 507.095 1545.31 507.095C1561.59 507.095 1569.72 517.004 1569.72 536.823V580.898H1551.23V537.563C1551.23 532.041 1550.29 527.999 1548.42 525.435C1546.64 522.871 1543.58 521.589 1539.24 521.589C1534.41 521.589 1530.61 523.315 1527.85 526.766C1525.08 530.118 1523.7 534.802 1523.7 540.817V580.898H1505.21V537.563C1505.21 532.041 1504.27 527.999 1502.4 525.435C1500.62 522.871 1497.61 521.589 1493.37 521.589C1488.53 521.589 1484.74 523.315 1481.97 526.766C1479.21 530.118 1477.83 534.802 1477.83 540.817V580.898H1459.33Z" fill="white"></path>
        <path d="M1633.35 582.377C1625.16 582.377 1618.11 580.849 1612.19 577.792C1606.27 574.736 1601.68 570.397 1598.42 564.777C1595.27 559.157 1593.69 552.501 1593.69 544.81C1593.69 537.316 1595.22 530.759 1598.28 525.139C1601.43 519.519 1605.72 515.131 1611.15 511.976C1616.67 508.722 1622.94 507.095 1629.94 507.095C1640.2 507.095 1648.29 510.349 1654.21 516.857C1660.23 523.364 1663.24 532.238 1663.24 543.479V548.951H1611.45C1612.83 561.868 1620.23 568.327 1633.64 568.327C1637.69 568.327 1641.73 567.735 1645.78 566.552C1649.82 565.27 1653.52 563.298 1656.88 560.636L1662.05 573.06C1658.6 575.919 1654.26 578.187 1649.03 579.863C1643.8 581.539 1638.58 582.377 1633.35 582.377ZM1630.68 519.667C1625.26 519.667 1620.87 521.343 1617.51 524.695C1614.16 528.048 1612.14 532.584 1611.45 538.302H1647.85C1647.45 532.288 1645.78 527.703 1642.82 524.547C1639.96 521.294 1635.91 519.667 1630.68 519.667Z" fill="white"></path>
        <path d="M1686.76 580.898V508.574H1704.82V519.815C1707.28 515.673 1710.59 512.518 1714.73 510.349C1718.97 508.18 1723.71 507.095 1728.94 507.095C1746 507.095 1754.54 517.004 1754.54 536.823V580.898H1736.04V537.711C1736.04 532.091 1734.96 527.999 1732.79 525.435C1730.71 522.871 1727.46 521.589 1723.02 521.589C1717.59 521.589 1713.25 523.315 1710 526.766C1706.84 530.118 1705.26 534.605 1705.26 540.225V580.898H1686.76Z" fill="white"></path>
        <path d="M1815.35 582.377C1796.21 582.377 1786.64 572.912 1786.64 553.98V522.477H1772.73V508.574H1786.64V486.98H1805.14V508.574H1827.04V522.477H1805.14V552.945C1805.14 557.678 1806.18 561.227 1808.25 563.594C1810.32 565.96 1813.67 567.143 1818.31 567.143C1819.69 567.143 1821.12 566.996 1822.6 566.7C1824.08 566.305 1825.61 565.911 1827.19 565.517L1830 579.124C1828.22 580.11 1825.96 580.898 1823.19 581.49C1820.53 582.082 1817.92 582.377 1815.35 582.377Z" fill="white"></path>
    </svg>
</template>
